import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Contact } from "./components/contact";
import data from "./data/data.json";
import {BrowserRouter as Router, Routes, Route, Link, Navigate, HashRouter} from 'react-router-dom';
import { Home } from "./components/Home";
import "./App.css";
import CardsComponent from "./utils/CardsComponent";
import {PGPSH} from "./components/PGPSH";

const App = () => {

  return (
      <Router>
          <div>
              <Navigation data={data.Navigation} />
              {/* Define routes */}
              <Routes>
                  <Route path="/" element={<Home data={data} />} />
                  {data["Nos formations"].map((service, index) => (
                      <Route key={index} path={"/"+service.title} element={<CardsComponent title={service.text} data={data.Features[service.title]} />}/>
                  ))}
                  <Route path="/pgpsh" element={<PGPSH data={data["Texts"]} />} />
                  <Route path="*" element={<Navigate to="/" />} />
              </Routes>
              <Contact data={data.Contact} />
          </div>
      </Router>
  );
};

export default App;