import React, {useEffect} from "react";
import {Link, useLocation} from "react-router-dom";

export const Navigation = (props) => {
  const ScrollToAnchor = () => {
    const { hash } = useLocation();

    useEffect(() => {
      if (hash) {
        const element = document.querySelector(hash);
        console.log(element);
        if (element) {
          element.scrollIntoView({
              behavior: 'smooth',
              // alignToTop: true,
              block: 'center'
          });
        }
      }
    }, [hash]);

    return null;
  };

  const HomeLink = () => {
    return (
        <Link to="/#page-top" className="navbar-brand page-scroll">
          <img
              style={{
                borderRadius: '10px',
                  marginTop: '-50px',
                  border: '.5rem solid rgba(7, 153, 146, 0.5)',
              }}
              width={props.data.logo.size}
              src={props.data.logo.largeImage}
              alt={props.data.logo.title}
              title={props.data.logo.title}
          />
        </Link>
    );
  }
  const ServicesLink = () => {
    return (
        <li>
          <Link to="/#services" className="page-scroll" style={{color: "white", fontWeight: "bold"}}>
            Nos Services
          </Link>
        </li>
    );
  };

  const PGPSH = () => {
      return (
          <li>
              <Link to="/#pgpsh" className="page-scroll" style={{color: "white", fontWeight: "bold"}}>
                  Politique des PSH
              </Link>
          </li>
      )
  }
    const AboutLink = () => {
        return (
            <li>
                <Link to="/#apropos" className="page-scroll" style={{color: "white", fontWeight: "bold"}}>
                    A Propos
                </Link>
        </li>
    );
  };

  return (
      <>
        <ScrollToAnchor />
        <nav id="menu"
             className="navbar navbar-default navbar-fixed-top"
             style={{backgroundColor: "rgba(7, 153, 146, 1)"}}
        >
          <div className="container">
            <div className="navbar-header">
              <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
              >
                {" "}
                <span className="sr-only">Toggle navigation</span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
              </button>
                <HomeLink />
            </div>

            <div
                className="collapse navbar-collapse"
                id="bs-example-navbar-collapse-1"
            >
              <nav className="nav navbar-nav navbar-right">
                <ServicesLink />
                <PGPSH />
                <AboutLink />
                <li>
                  <a href="/#contact" className="page-scroll"
                     style={{color: "white", border: "5px solid rgba(255, 255, 255, 1)", padding: "10px", borderRadius: "10px"}}>
                      <i className="fa fa-phone fa-2x"></i>&nbsp;&nbsp;<b style={{fontSize: "16pt"}}>+33 6 20 54 47 47 / +33 2 85 85 98 18</b>
                  </a>
                </li>
              </nav>
            </div>
          </div>
        </nav>
      </>
  );
};