import React from "react";

export const PGPSH = (props) => {
    const data = props.data;
    console.log(data);
  return (
      <div id="pgpsh" style={{margin: "15em"}}>
          <div className="container">
              <div className="row">
                  <h1>{data["title"]}</h1>
                  <br/>
                  <p dangerouslySetInnerHTML={{__html: data["content"]}}></p>
                  <button>
                      <a title={'NJRCFA - ' + data["title"]} href="files/NJR CFA - Procédure de gestion des PSH.pdf">
                          Voir notre procédure de gestion des PSH</a>
                  </button>
              </div>
          </div>
      </div>
  );
}