import {Header} from "./header";
import {Services} from "./services";
import {About} from "./about";
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import {PGPSH} from "./PGPSH";


export const Home = (props) => {

    return (
        <>
            <Header data={props.data.Header} />
            <Services data={props.data["Nos formations"]} />
            <PGPSH data={props.data["Texts"]["PGPSH"]} />
            <About data={props.data.About} />
        </>
    );
}