import React, { useState } from "react";
import "./styles/CardsComponent.css";

const Modal = ({ show, onClose, title, content }) => {
    if (!show) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close" onClick={onClose}>
                    ✖
                </button>
                <h2 style={{textAlign: "center"}}>{title}</h2>
                <div>{content}</div>
            </div>
        </div>
    );
};

const Content = ({ data }) => {
    const renderValue = (key, value) => {
        if (Array.isArray(value)) {
            return (
                <ul>
                    {value.map((item, index) =>
                        typeof item === "object" && item !== null ? (
                            <li key={index}>
                                {renderObject(item)}
                            </li>
                        ) : (
                            <li key={index}>{item}</li>
                        )
                    )}
                </ul>
            );
        } else if (typeof value === "object" && value !== null) {
            return renderObject(value);
        } else {
            return value;
        }
    };

    const renderObject = (obj) => {
        return (
            <div>
                {Object.entries(obj).map(([subKey, subValue]) => (
                    <p key={subKey}>
                        <strong>{subKey}:</strong> {renderValue(subKey, subValue)}
                    </p>
                ))}
            </div>
        );
    };

    return (
        <div className="content-modal">
            {Object.entries(data).map(([key, value]) => (
                <div key={key} className="content-section">
                    <h2>{key}</h2>
                    {renderValue(key, value)}
                </div>
            ))}
        </div>
    );
};

const CardsComponent = ({ data, title }) => {
    const [selectedCard, setSelectedCard] = useState(null);
    const formations = Object.entries(data);

    const cardImages = {
        "SSIAP 1": "img/NJR CFA - logo SSIAP1.jpg",
        "SSIAP 2": "img/NJR CFA - logo SSIAP2.jpg",
        "SSIAP 3": "img/NJR CFA - logo SSIAP3.jpg",
        "TFP APS": "img/NJR CFA - logo TFP APS.jpg",
        "H0B0": "img/NJR CFA - logo Habilitation Electrique H0B0.jpg",
        "SST": "img/NJR CFA - logo Sauveteur Secouriste du Travail.jpg",
        "Gestes et Postures": "img/NJR CFA - logo Gestes et Postures.jpg",
        "PRAP IBC": "img/NJR CFA - logo PRAP IBC.jpg",
        "Équipier de Première Intervention": "img/NJR CFA - logo Equipier de Première Intervention.jpg",
        "DUERP": "img/NJR CFA - logo DUERP.jpg",
        "MAC SST": "img/NJR CFA - logo MAC SST.jpg",
        "MAC TFP APS et RAN": "img/NJR CFA - logo MAC TFP APS.jpg",
        "MAC SSIAP 2": "img/NJR CFA - logo MAC SSIAP2.jpg",
        "MAC SSIAP 3": "img/NJR CFA - logo MAC SSIAP3.jpg",
        "MAC et RAN SSIAP 1": "img/NJR CFA - logo MAC SSIAP1.jpg",
        "Rédaction et MAJ du DUERP": "img/NJR CFA - logo DUERP.jpg",
    };
    const cardDocuments = {
        "SSIAP 1": "files/NJR CFA - Formation SSIAP1.pdf",
        "SSIAP 2": "files/NJR CFA - Formation SSIAP2.pdf",
        "SSIAP 3": "files/NJR CFA - Formation SSIAP3.pdf",
        "TFP APS": "files/NJR CFA - Formation TFP APS.pdf",
        "H0B0": "files/NJR CFA - Formation H0B0.pdf",
        "SST": "files/NJR CFA - Formation SST.pdf",
        "Gestes et Postures": "files/NJR CFA - Formation Gestes et Postures.pdf",
        "PRAP IBC": "files/NJR CFA - Formation PRAP IBC.pdf",
        "Équipier de Première Intervention": "files/NJR CFA - Formation Équipier de Première Intervention.pdf",
        "DUERP": "files/NJR CFA - Formation DUERP.pdf",
        "MAC SST": "files/NJR CFA - Formation MAC SST.pdf",
        "MAC TFP APS et RAN": "files/NJR CFA - Formation MAC TFP APS et RAN.pdf",
        "MAC SSIAP 2": "files/NJR CFA - Formation MAC SSIAP2.pdf",
        "MAC SSIAP 3": "files/NJR CFA - Formation MAC SSIAP3.pdf",
        "MAC et RAN SSIAP 1": "files/NJR CFA - Formation MAC et RAN SSIAP1.pdf",
        "Rédaction et MAJ du DUERP": "files/NJR CFA - modèle DUERP.pdf"
    };

    const toKeep = {
        "SST": "SST",
        "Équipier de Première Intervention": "Équipier de Première Intervention"
    };

    return (
        <div style={{paddingTop: "130px", overflowAnchor: "none"}} className="container">
            <h2 id="cards-title" style={{textAlign: "center"}}>{title}</h2>
            <div className="cards-container" id="cards-content">
                {formations
                    // .filter(([title]) => title === toKeep[title])
                    .map(([title, content], index) => {
                    // if (title !== toKeep[title]) {
                    //     return (
                    //         <div key={index} className="card">
                    //             <h3>...Programme en cours de réalisation</h3>
                    //         </div>
                    //     );
                    // }
                    return (
                        <div
                            key={index}
                            className="card"
                        >
                            <div style={{padding: "10px", border: "1px solid green", borderRadius: "10px"}}
                                 onClick={() => setSelectedCard({title, content})}>
                                <div className="card-image">
                                    <img
                                        src={cardImages[title] || title}
                                        alt={"NJR CFA: " + content.Titre}
                                    />
                                </div>
                                <div className="card-header">
                                    <h3>{title}</h3>
                                    <p>{content.Titre}</p>
                                </div>
                                <p style={{marginTop: "10px", color: "blueviolet"}}>Voir le détail</p>
                            </div>
                            <hr/>
                            <div className="card-footer">
                                <button>
                                    <a title={'NJRCFA - ' + content.Titre} href={cardDocuments[title]}>Télécharger le
                                        programme</a>
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>

            <Modal
                show={!!selectedCard}
                onClose={() => setSelectedCard(null)}
                title={selectedCard?.title}
                content={<Content data={selectedCard?.content}/>}
            />
        </div>
    );
};

export default CardsComponent;